import {each} from 'lodash'
import Page from 'app/components/page'
import FormValidator from 'app/components/form_validator'
import { DialogOptions } from 'app/components/dialog'
import ForgotPasswordDialog from 'app/components/forgot_password_dialog'
import {PASSWORD_PATTERN, ResponseStatus} from 'app/constants/generals'
import Popover from 'bootstrap/js/src/popover'
import i18n from 'i18n'
import * as Agent from 'superagent'
import * as Routes from 'routes'
import { debounce } from 'lodash'

const constraints = {
  'password': {
      presence: true,
      format: {
        pattern: PASSWORD_PATTERN,
        message: "should be at least 10 characters including at least 1 uppercase letter, 1 lowercase letter, 1 special character and 1 numeric"
      },
      length: {
        maximum: 40,
        tooLong: "is too long (maximum is 40 characters)."
      }
  },
  'password_confirmation': {
      presence: true,
      equality: {
          attribute: 'password',
      },
  }
}
const emailConstraints = {
  'email' : {
    presence: true,
    format: {
      pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    }
  }
}

export class LoginPage extends Page {
  private _elapsedTime

  constructor() {
    super()

    // Serverside Load Error
    this.validateForm()
    this.validateResetPasswordForm()
    this.forgetPasswordDialog()
    this.bindLoginAccount()
    this.showMessage()
    this.validateTfaInput()
    this.initPopover()
    this.initTooltips()
    this.initShowHidePassword()

    let tfaCodeInput = document.getElementById('user_tfa_attempt')
    if (tfaCodeInput) Page.inputNumericOnly(tfaCodeInput)

    this.initRemainingTime()
    this.initOtpCode()
  }


  private bindLoginAccount() {
    let loginItems = document.getElementsByClassName('item')
    each(loginItems, function(e) {
      let is_disabled = e.classList.contains('disabled')

      if (is_disabled == false) {
        e.addEventListener('click', debounce(function () {
          let el = e.querySelector('input[type=radio]') as HTMLInputElement
          el.checked = true
          const formTarget = document.getElementById('edit_user') as HTMLFormElement

          formTarget.submit()
        }, 500))
      }
    })

  }

  private forgetPasswordDialog() {
    let buttonElm = document.getElementById('forget-password') as HTMLButtonElement

    if (buttonElm) {
      buttonElm.disabled = true
      let emailEl = document.querySelector('#email') as HTMLInputElement

      emailEl.oninput = function() {
        if (emailEl.value.length > 0) {
          buttonElm.disabled = false
        } else {
          buttonElm.disabled = true
        }
      }

      this.validateEmail(buttonElm)
    }
  }

  private validateEmail(buttonElm) {
    let form = document.getElementById('forgot_password_form') as HTMLFormElement,
        formInputs = form.querySelectorAll('input[type=email]'),
        emailElm = document.getElementById('email') as HTMLInputElement,
        formValidator = new FormValidator(form, formInputs)
    const self = this

    formValidator.setValidIndicator(false)

    formValidator.constraints = emailConstraints
    formValidator.registerOnSubmit(
      null,
      function (form, errors) {
        let options: DialogOptions = {
          border: false,
          close_button: false,
          title: i18n.t('sessions.password.forget_password_request_title'),
          content: i18n.t('sessions.password.forget_password_request_body', { email: emailElm.value })
        }
        let dialog = new ForgotPasswordDialog(buttonElm, options)

        dialog.onSave((formData) => {
          form.submit()
        })
        dialog.show()
      }
    )
  }

  private validateForm() {
    let form = document.querySelector('#edit_user')
    if (form) {
      let formInputs = form.querySelectorAll('input[type=email], input[type=password]')

      let formValidator = new FormValidator(form, formInputs)
      formValidator.showInputErrorsOnLoad()
    }
  }

  private validateResetPasswordForm() {
    let form = document.querySelector('#new_password')
    if (form) {
      let formInputs = form.querySelectorAll('input[type=password]')

      let formValidator = new FormValidator(form, formInputs)

      formValidator.constraints = constraints
      formValidator.registerOnSubmit(
          null,
          function (form, errors) {
            form.submit();
          })
    }
  }

  private validateTfaInput() {
    let form = document.querySelector('#tfa_input')
    if (form) {
      let formInputs = form.querySelectorAll('input[name=tfa_code]')

      let formValidator = new FormValidator(form, formInputs)
      formValidator.constraints = {
        'tfa_code': {
          presence: true
        }
      }
      formValidator.registerOnSubmit(
          null,
          function (form, errors) {
            form.submit();
          })
    }
  }

  private initPopover() {
    let tooltip = document.getElementsByClassName('popover')
    if (tooltip && tooltip.length > 0) {
      new Popover(tooltip, {placement: 'top', trigger: 'hover'})
    }
  }

  private initShowHidePassword(){
    const passwordIcon = document.querySelectorAll('.password-open')

    each(passwordIcon, function (icon) {
      let passwordInput = icon.previousElementSibling.querySelector('input') as HTMLInputElement

      if (passwordInput == null) {
        passwordInput = icon.previousSibling as HTMLInputElement
      }

      icon.addEventListener('click', function(e){
        if(passwordInput.getAttribute('type') == 'password') {
          passwordInput.setAttribute('type', 'text')
          icon.classList.replace('solid-eye', 'solid-eye-slash')
        }
        else {
          passwordInput.setAttribute('type', 'password')
          icon.classList.replace('solid-eye-slash', 'solid-eye')
        }
      })
    })
  }

  private initRemainingTime(){
    let remaininTimeElm = document.querySelector('#remaining-time')
    let that = this
    if (remaininTimeElm) {
      this._elapsedTime = parseFloat(remaininTimeElm.getAttribute('data-elapsed'))

      this.blockOTPForm()
      this.startTimer(this._elapsedTime, remaininTimeElm, function () {
        let resendBtn = document.querySelector('#resend-otp')
        resendBtn.classList.remove('disabled')
        that._elapsedTime = null
      })
    }

  }

  private blockOTPForm() {
    let form = document.querySelector('#otp-input') as HTMLFormElement
    let resendBtn = document.querySelector('#resend-otp') as HTMLButtonElement

    form.addEventListener('submit', function (event) {
      event.preventDefault()
    })

    resendBtn.onclick = () => {
      if (!this._elapsedTime) {
        form.submit()
      }
    }
  }

  private startTimer(duration, display, callback) {
    if (duration < 0) {
      duration = 0
    }

    let timer = duration, minutes, seconds
    let interval = setInterval(function () {
        minutes = parseInt(String(timer / 60), 10)
        seconds = parseInt(String(timer % 60), 10)

        minutes = minutes < 10 ? "0" + minutes : minutes
        seconds = seconds < 10 ? "0" + seconds : seconds

        display.textContent = `${minutes} min. ${seconds} seconds.`

        if (--timer < 0) {
          clearInterval(interval)
          callback()
        }
    }, 1000)
  }

  private initOtpCode(){
    let input = document.querySelector('input#otp_code')

    if (input){
      Page.inputNumericOnly(input)
    }
  }
}
