import i18n from 'i18n'
import {Dialog, DialogOptions} from 'app/components/dialog'

export default class ForgotPasswordDialog extends Dialog {
    _buttons = [
        {
            element: 'button.btn.btn-outline-primary.btn-sm',
            text: i18n.t('button.cancel'),
            callback: this.cancelButtonClick,
            attrs: {'data-dismiss': 'modal', type: 'button'}
        },
        {
            element: 'button.btn.btn-primary.btn-sm',
            text: i18n.t('button.continue'),
            callback: this.saveButtonClick,
        }
    ]

    constructor(elem: HTMLElement, options?: DialogOptions) {
      super(elem, options)
      this.constructModal(elem)
    }
}
